<template>
    <v-card :height="kioskAnalyticsDimension.userSessionheight" flat>
        <v-row class="fill-height">
            <!-- <v-col cols="12" align="center" class="font-weight-bold">Date: {{ displayDate }}</v-col> -->
            <v-col cols="5" align-self="center">
                <annual-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === '2025'"></annual-bar-chart>
                <annual-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Year'"></annual-bar-chart>
                <this-week-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'This Week'"></this-week-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Today'"></today-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Yesterday'"></today-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Date'"></today-bar-chart>
                <this-week-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Week'"></this-week-bar-chart>
                <FiscalYearBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Fiscal Year'"></FiscalYearBarChart>
            </v-col>
            <v-col cols="4" align="center" align-self="center">
                <pie-chart></pie-chart>
            </v-col>
            <v-col cols="3" align="center" align-self="center">
                <language-bar-chart></language-bar-chart>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AnnualBarChart from '../../../graphs/annualBarChart.vue';
import ThisWeekBarChart from '../../../graphs/thisWeekBarChart.vue';
import TodayBarChart from '../../../graphs/todayBarChart.vue';
import FiscalYearBarChart from '../../../graphs/fiscalYearBarChart.vue';
import PieChart from '../../../graphs/pieChart.vue';
import LanguageBarChart from '../../../graphs/languageBarChart.vue';
export default {
    name: 'reports',
    props: {
        userSessionList: Array,
        selectedDuration: String
    },
    computed: {
        ...mapGetters(['kioskAnalyticsDimension', 'selectedSingleDate', 'weekDatePicker', 'selectedWeekDate', 'singleDatePicker', 'displayDate', 'closeWithoutSelection'])
    },
    components: {
        AnnualBarChart,
        ThisWeekBarChart,
        TodayBarChart,
        PieChart,
        LanguageBarChart,
        FiscalYearBarChart
    },
    data() {
        return {
            //
        }
    },
    watch: {
        closeWithoutSelection(newVal) {
            if (newVal === 1) {
                //
                this.selectedDuration = 'Today'

            }
        }
    }

}
</script>
<style>
.cardBorderStyle {
    border-right: solid 1px #111111 !important;
    border-radius: 0px !important;
}
</style>
